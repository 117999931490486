import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/burger.png'


const _withScopeId = n => (_pushScopeId("data-v-082cae5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["alt"]
const _hoisted_3 = { class: "is-flex" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = { class: "is-flex" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IsNotAuthenticated = _resolveComponent("IsNotAuthenticated")!
  const _component_IsAuthenticated = _resolveComponent("IsAuthenticated")!
  const _component_LanguageSwitcher = _resolveComponent("LanguageSwitcher")!
  const _component_MobileNav = _resolveComponent("MobileNav")!

  return (_openBlock(), _createElementBlock("header", {
    "data-testid-header": "",
    class: _normalizeClass({ 'landing-page': _ctx.landingPage })
  }, [
    _createElementVNode("div", {
      role: "navigation",
      "aria-label": _ctx.$t('navigation.landing-page')
    }, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "ml-0",
        "aria-label": _ctx.$t('general.logo')
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _imports_0,
            alt: _ctx.$t('general.logo')
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["aria-label"])
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("nav", {
        class: "is-flex is-hidden-touch",
        "aria-label": _ctx.$t('navigation.desktop')
      }, [
        _createVNode(_component_router_link, {
          class: "ml-3",
          to: { path: '/search', query: { lang: _ctx.locale, page: '1' } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.artworks")), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: "/artists" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.artists")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/themes" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.themes")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/acquisitions" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.acquisitions")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/about-collections" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.about-collections")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/my-collections" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("navigation.my-collections")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_IsNotAuthenticated, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, { to: { name: 'LandingPage', query: { view: 'login' } } }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("navigation.log-in")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_IsAuthenticated, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              onClick: _ctx.logout,
              to: { name: 'LandingPage' }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("navigation.logout")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ], 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          "data-testid-burger": "",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBurgerClick && _ctx.onBurgerClick(...args))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onBurgerClick && _ctx.onBurgerClick(...args)), ["enter"])),
          class: "burger ml-5 is-hidden-desktop",
          tabindex: "0",
          "aria-label": _ctx.$t('general.menu'),
          role: "button"
        }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: _ctx.$t('general.burger')
          }, null, 8, _hoisted_7)
        ], 40, _hoisted_6),
        _createVNode(_component_LanguageSwitcher, { class: "ml-5" })
      ])
    ]),
    _createVNode(_component_MobileNav, {
      closed: _ctx.closed,
      "onUpdate:closed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.closed) = $event))
    }, null, 8, ["closed"])
  ], 2))
}