import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21e36bb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "collection-block public p-2"
}
const _hoisted_2 = {
  key: 1,
  class: "collection-block private p-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionBlockCollage = _resolveComponent("CollectionBlockCollage")!
  const _component_CollectionBlockLabel = _resolveComponent("CollectionBlockLabel")!
  const _component_CollectionBlockOwner = _resolveComponent("CollectionBlockOwner")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'CollectionDetails', params: { id: _ctx.collection.id } }
  }, {
    default: _withCtx(() => [
      (_ctx.collection.public)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_CollectionBlockCollage, { collection: _ctx.collection }, null, 8, ["collection"]),
            _createElementVNode("div", null, [
              _createVNode(_component_CollectionBlockLabel, { collection: _ctx.collection }, null, 8, ["collection"]),
              _createVNode(_component_CollectionBlockOwner, {
                collection: _ctx.collection,
                darkIcon: true
              }, null, 8, ["collection"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_CollectionBlockLabel, { collection: _ctx.collection }, null, 8, ["collection"]),
            _createVNode(_component_CollectionBlockOwner, { collection: _ctx.collection }, null, 8, ["collection"])
          ]))
    ]),
    _: 1
  }, 8, ["to"]))
}