
import { defineComponent, computed } from "vue";
import MobileNav from "./MobileNav.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
import { IsNotAuthenticated, IsAuthenticated } from "@kunsten/core";
import { useAPI } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Header",
    props: {
        landingPage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            closed: true,
        };
    },
    setup() {
        const { logout, userData } = useAPI();
        const { locale } = useI18n();

        const userId = computed(() => {
            return userData.value?.id;
        });

        return { logout, userId, locale };
    },
    components: {
        MobileNav,
        LanguageSwitcher,
        IsNotAuthenticated,
        IsAuthenticated,
    },
    methods: {
        onBurgerClick() {
            this.closed = !this.closed;
        },
    },
});
