import { readonly, ref, computed } from "vue";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { useHttp } from "../api";
import { UserCollectionDto } from "../dto/user-collection";
import { HydraCollection } from "../dto/hydra";

export function useUserCollectionsSearch() {
    const http = useHttp();

    const loading = ref<boolean>(false);
    const initLoading = ref<boolean>(true);
    const results = ref<UserCollectionDto[]>([]);
    const page = ref<number>(1);
    const itemsPerPage = ref<number>(16);
    const totalItems = ref<number>(0);

    let cancelToken: CancelTokenSource | undefined;

    const query = (): Promise<AxiosResponse<HydraCollection<UserCollectionDto>>> => {
        if (cancelToken) cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        loading.value = true;

        const params = {
            page: page.value,
            itemsPerPage: itemsPerPage.value,
            "order[count]": "desc",
        };

        return http
            .get<HydraCollection<UserCollectionDto>>("/api/user_collections", {
                params,
                cancelToken: cancelToken.token,
            })
            .then((res) => {
                loading.value = false;
                if (initLoading.value) initLoading.value = false;
                cancelToken = undefined;
                return res;
            })
            .catch((e: Error) => {
                cancelToken = undefined;

                if (!axios.isCancel(e)) {
                    loading.value = false;
                }

                throw e;
            });
    };

    const search = (): Promise<AxiosResponse<HydraCollection<UserCollectionDto>>> => {
        return query().then((res) => {
            results.value = res.data["hydra:member"];
            totalItems.value = res.data["hydra:totalItems"];
            return res;
        });
    };

    const loadMore = (): Promise<AxiosResponse<HydraCollection<UserCollectionDto>>> => {
        if (loading.value) return Promise.reject(new Error("Cannot load more when query is in progress!"));

        page.value = page.value + 1;
        return query().then((res) => {
            results.value = [...results.value, ...res.data["hydra:member"]];
            return res;
        });
    };

    const canLoadMore = computed(() => {
        return page.value * itemsPerPage.value < totalItems.value;
    });

    search();

    return {
        initLoading: readonly(initLoading),
        loading: readonly(loading),
        results: readonly(results),
        totalItems: readonly(totalItems),
        page: readonly(page),
        canLoadMore,
        search,
        loadMore,
    };
}
