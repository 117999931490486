
import { defineComponent } from "vue";
import CollectionBlock from "./CollectionBlock.vue";
import { useUserCollectionsResource } from "@kunsten/core";

export default defineComponent({
    name: "PopularCollections",
    components: { CollectionBlock },
    setup() {
        const { entities, loading } = useUserCollectionsResource({
            page: "1",
            pagination: "true",
            itemsPerPage: 8,
            "order[count]": "desc",
        });

        return { results: entities, loading };
    },
});
